<template>
  <div class="reveal" :class="type">

    <template v-if="!loading && note">

      <span><img v-if="note.text" :src="icons.note" alt="Note"/></span>
      <p v-if="note.text">{{ note.text }}</p>
      <div v-if="preview" class="preview" :style="preview"></div>

    </template>

    <Loading v-if="loading"/>

  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'

import Filters from '@/utils/filters'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NotesNoteReveal',

  data() {
    return {
      loading: true,
      icons: {
        note: require( '@/assets/icons/notes/note.svg' )
      }
    }
  },

  props: {
    noteId: Number,
    close: Function
  },

  filters: {
    ...Filters
  },

  components: {
    Loading
  },

  computed: {
    ...mapGetters( 'Notes', {
      note: 'note'
    }),

    preview() {
      if ( !this.note || this.note.text ) return
      const url = this.note.image || this.note.sketch
      return `background-image: url(${ url });`
    },

    type() {
      if ( !this.note ) return
      return this.note.text ? 'text' : ( this.note.image ? 'image' : 'sketch' )
    }
  },

  methods: {
    ...mapActions( 'Notes', {
      read: 'read'
    })
  },

  async mounted() {
    await this.read( this.noteId )
    this.loading = false
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.reveal {
  span {
    overflow: hidden;
    height: 64px;
    @include flex-center;

    img {
      width: 64px;
      height: 64px;
      position: absolute;
      transform: rotate( -20deg );
    }
  }

  p {
    font-size: 16px;
    text-align: center;
  }

  .preview {
    height: 400px;
    @include image-center;
    background-size: contain;
    border-radius: 2px;
  }

  .buttons {
    display: grid;
    grid-gap: 16px;
    grid-auto-flow: column;
  }

  .spinner-container {
    height: 100%;
  }

  &.image {
    .preview {
      @include box-shadow( $shadow );
    }
  }
}
</style>
