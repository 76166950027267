<template>
  <form class="preview" @submit.prevent="submit">

    <Details :note="note"/>

    <Distance :note="note"/>

    <div class="buttons">
      <!-- Cancel -->
      <button role="button" @click.stop="close" alt="Cancel" class="submit reject">
        <span class="shadow"></span>
        <span class="edge"></span>
        <span class="content"><p>Cancel</p></span>
      </button>

      <!-- Reveal -->
      <button role="button" class="submit" alt="Reveal" :class="{ disabled: !isClose }">
        <span class="shadow"></span>
        <span class="edge"></span>
        <span class="content"><p>Reveal</p></span>
      </button>
    </div>

  </form>
</template>

<script>
import Details from '@/components/notes/note/preview/Details.vue'
import Distance from '@/components/notes/note/preview/Distance.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'NotesNotePreview',

  data() {
    return {
      icons: {
        note: require( '@/assets/icons/notes/note.svg' )
      }
    }
  },

  props: {
    note: Object,
    submit: Function,
    close: Function
  },

  components: {
    Details,
    Distance
  },

  computed: {
    ...mapGetters( 'Limits', {
      limit: 'notes'
    }),

    ...mapGetters( 'Location', {
      location: 'location'
    }),

    distance() {
      const { latitude, longitude } = this.location
      const { x, y } = this.note.coordinates
      return this.$Utils.Location.distance( latitude, longitude, x, y )
    },

    isClose() {
      const threshold = this.limit.distance
      return this.distance <= threshold
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.preview {
  display: grid;
  grid-template-rows: repeat( 3, auto );
  grid-gap: 24px;

  $size: 124px;

  span {
    overflow: hidden;
    position: relative;
    height: $size;
    @include flex-center;

    img {
      width: $size;
      height: $size;
      transform: rotate( -12deg );
      margin: 0 auto;
      position: absolute;
      border: 1px solid black;
      border-radius: 50%;
      padding: 16px;
    }
  }

  .buttons {
    display: grid;
    grid-template-columns: repeat( 2, 1fr );
    grid-gap: 24px;
  }
}
</style>
