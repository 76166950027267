<template>
  <div class="modal-container bk" @mousedown.stop="close">
    <div class="modal" @mousedown.stop>
      <div @click.stop ref="scroller" v-on:scroll="scrolling" class="scroller">
        
        <ul v-if="showNotes" class="notes-list">
          <Note v-for="note in notes" :key="note.id" :note="note"/>
        </ul>

        <Loading v-if="!showNotes && loading"/>

        <button role="button" @click="load" alt="Load More" v-if="showLoadMore" class="pagination" :class="{ loading }">
          <p>{{ label }}</p>
          <img v-if="!loading" :src="icons.load" alt="Load more"/>
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import Note from '@/components/notes/mine/Note.vue'
import Loading from '@/components/Loading.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NotesMine',

  data() {
    return {
      loading: false,
      loaded: false,
      canLoadMore: true,
      infScroll: true,
      error: null,
      icons: {
        load: require( '@/assets/icons/shared/expand.svg' )
      }
    }
  },

  components: {
    Loading,
    Note
  },

  props: {
    close: Function
  },

  computed: {
    ...mapGetters( 'Notes', {
      notes: 'mine',
      count: 'countMine'
    }),

    ...mapGetters( 'Limits', {
      limit: 'notes'
    }),
    
    label() {
      return this.loading ? 'Loading' : 'Load More'
    },

    showNotes() {
      return !this.error && this.loaded
    },

    showLoadMore() {
      return this.canLoadMore && !this.infScroll && this.showNotes
    },

    offset() {
      return !this.loaded ? 0 : this.count
    },

    scroller() {
      return this.$refs.scroller
    }
  },

  methods: {
    ...mapActions( 'Notes', {
      fetch: 'getMine'
    }),

    async load() {
      if ( !this.canLoadMore || this.loading ) return

      this.error = null
      this.loading = true

      const count = await this.fetch({ offset: this.offset }).catch( e => this.error = e )

      if ( !this.loaded && !this.error ) this.loaded = true
      this.loading = false

      const limit = this.limit.pagination
      this.canLoadMore = count === limit || this.error
      
      this.$nextTick(() => {
        this.infScroll = this.$Utils.Scrolling.infinite( this.scroller )
      })
    },

    async reload() {
      this.canLoadMore = true
      await this.load()
    },

    scrolling() {
      const check = this.scroller && this.infScroll && this.canLoadMore && !this.loading
      if ( check && this.$Utils.Scrolling.bottom( this.scroller )) this.load()
    }
  },

  async mounted() {
    await this.load()
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.modal {
  min-height: 280px;

  .scroller {
    overflow-y: scroll;
    background-color: $off-white;
    position: relative;
    border-radius: 4px;
    display: block;
    height: 280px;

    ul {
      width: 100%;
      padding: 0 4px;
    }

    .spinner-container {
      margin-top: 50px;
    }

    button.pagination {
      background-color: unset;
      border: none;
    }
  }
}
</style>
