<template>
  <div class="modal-container bk" @mousedown.stop="close">
    <form class="notes-new modal" @mousedown.stop>

      <Types v-if="!type" :select="t => { type = t }"/>

      <Text_ v-if="text" :close="close"/>
      <Image_ v-if="image" :close="close"/>
      <Sketch_ v-if="sketch" :close="close"/>

    </form>
  </div>
</template>

<script>
import Types from '@/components/notes/new/Types.vue'
import Text_ from '@/components/notes/new/Text.vue'
import Image_ from '@/components/notes/new/Image.vue'
import Sketch_ from '@/components/notes/new/Sketch.vue'

export default {
  name: 'NotesNew',

  data() {
    return {
      type: null
    }
  },

  props: {
    close: Function
  },

  components: {
    Types,
    Text_,
    Image_,
    Sketch_
  },

  computed: {
    text() {
      return this.type && this.type.toUpperCase() === 'TEXT'
    },

    image() {
      return this.type && this.type.toUpperCase() === 'IMAGE'
    },

    sketch() {
      return this.type && this.type.toUpperCase() === 'SKETCH'
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.notes-new {
  min-height: 80px;
}
</style>
