<template>
  <li>
    <div class="flag" :title="hover" v-if="flag" :style="flag" alt="Flag"></div>
    
    <p class="title">{{ type | camel_case }} | {{ distance }}</p>

    <span class="views" :title="views">
      <img :src="icons.eye" alt="Eye"/>
      <p>{{ views }}</p>
    </span>

    <p class="date" :title="note.createdAt">{{ note.createdAt | date }}</p>
    
  </li>
</template>

<script>
import { mapGetters } from 'vuex'
import Filters from '@/utils/filters'

export default {
  name: 'NotesMineNote',

  data() {
    return {
      icons: {
        eye: require( '@/assets/icons/notes/eye.svg' )
      }
    }
  },

  props: {
    note: Object
  },

  filters: {
    ...Filters
  },

  computed: {
    ...mapGetters( 'Location', {
      location: 'location'
    }),

    type() {
      return this.note.text ? 'TEXT' : ( this.note.image ? 'IMAGE' : 'SKETCH' )
    },

    views() {
      const views = this.note.views
      return views >= 100000 ? this.$Utils.Filters.abbriviate( views ) : views.toLocaleString()
    },

    flag() {
      const flag = `${ this.note.code.toLowerCase() }.png`
      const url = `${ process.env.VUE_APP_S3_ROUTE }/flags/${ flag }`
      return `background-image: url(${ url });`
    },

    hover() {
      return `${ this.note.country } (${ this.note.code })`
    },

    distance() {
      const { latitude, longitude } = this.location
      const { x, y } = this.note.coordinates
      const km = this.$Utils.Location.distance( latitude, longitude, x, y )
      return `${ km.toLocaleString() } km`
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

li {
  width: 100%;
  padding: 12px 12px;
  position: relative;
  display: grid;
  grid-template-areas: "flag title views" "flag date views";
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-gap: 2px 16px;

  &:not( :first-child ) {
    &::after {
      left: 20px;
      right: 20px;
      content: '';
      position: absolute;
      top: 0;
      height: 1px;
      background-color: $light-grey;
    }
  }

  &:hover {
    background-color: rgba( $hover, $opacity-high );
  }

  .flag {
    grid-area: flag;
    $size: 28px;
    width: $size;
    min-width: $size;
    height: $size;
    border-radius: 50%;
    @include box-shadow( $shadow );
    @include image-center;
  }

  span {
    grid-area: views;
    @include flex-center;

    img {
      width: 24px;
      height: 24px;
    }

    p {
      color: $med-dark-grey;
      padding-left: 8px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 900;
    @include ellipsis;
  }

  .title {
    grid-area: title;
  }

  .date {
    grid-area: date;
    font-size: 12px;
    color: $med-dark-grey;
  }
}
</style>
