<template>
  <div class="sketch-new">

      <!-- Sketch -->
      <div ref="container" class="sketch">
        <canvas ref="canvas"></canvas>
        <img @click.stop="clear" :src="icons.clear" alt="Cross"/>
      </div>

      <div class="buttons">
        <!-- Cancel -->
        <button role="button" @click.stop="close" alt="Cancel" class="submit reject">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><p>Cancel</p></span>
        </button>

        <!-- Drop -->
        <button role="button" class="submit" @click.prevent="submit" alt="Drop" :class="{ disabled: !canDrop }">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><p>Drop</p><span class="coin" v-if="showCoin"><img :src="icons.coin" alt="Coin"/></span><p v-if="showCoin">{{ cost }}</p></span>
        </button>
      </div>

      <Insufficient v-if="insufficient" :close="() => this.insufficient = false" :price="this.price"/>

  </div>
</template>

<script>
import Insufficient from '@/components/shop/Insufficient.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NotesNewImage',

  data() {
    return {
      loading: false,
      insufficient: false,
      icons: {
        clear: require( '@/assets/icons/shared/cross.svg' ),
        coin: require( '@/assets/icons/shared/coin.svg' )
      },

      //Canvas
      isDrawing: false,
      blank: true,
      posX: 0,
      posY: 0,
      canvas: null,
      ctx: null,
      background: '#fcfcfc',
      pen: {
        color: '#121212',
        width: 2
      }
    }
  },

  props: {
    close: Function
  },

  components: {
    Insufficient
  },

  computed: {
    ...mapGetters( 'Limits', {
      s3: 's3'
    }),

    ...mapGetters( 'Prices', {
      price: 'note'
    }),

    ...mapGetters( 'Auth', {
      balance: 'balance'
    }),

    canDrop() {
      return !this.blank && !this.loading
    },

    showCoin() {
      return this.cost && this.canDrop
    },

    cost() {
      return this.price ? this.$Utils.Filters.abbriviate( this.price ) : null
    }
  },

  methods: {
    ...mapActions( 'Notes', {
      new: 'new'
    }),

    async submit() {
      if ( !this.canDrop ) return
      if ( this.price && this.balance < this.price ) return this.insufficient = true
      const blob = await this.blob( this.canvas )
      this.new({ sketch: blob })
      this.close()
    },

    blob( canvas ) {
      return new Promise (( resolve ) => canvas.toBlob( blob => resolve( blob )))
    },

    getXPos( e ) {
      const rect = e.currentTarget.getBoundingClientRect()
      return this.$_.get( e, 'touches[0].clientX', e.clientX ) - rect.left
    },

    getYPos( e ) {
      const rect = e.currentTarget.getBoundingClientRect()
      return this.$_.get( e, 'touches[0].clientY', e.clientY ) - rect.top
    },

    down( e ) {
      this.posX = this.getXPos( e )
      this.posY = this.getYPos( e )
      this.isDrawing = true
    },

    up( e ) {
      this.posX = this.getXPos( e )
      this.posY = this.getYPos( e )
      this.isDrawing = false
    },

    move( e ) {
      if ( !this.isDrawing ) return
      this.blank = false

      const { color, width } = this.pen

      this.ctx.beginPath()
      this.ctx.lineWidth = width
      this.ctx.strokeStyle = color
      this.ctx.moveTo( this.posX, this.posY )
      this.posX = this.getXPos( e )
      this.posY = this.getYPos( e )
      this.ctx.lineTo( this.posX, this.posY )
      this.ctx.lineJoin = this.ctx.lineCap = 'round'
      this.ctx.stroke()
    },

    resize() {
      const container = this.$refs.container

      this.canvas.width = container.offsetWidth
      this.canvas.height = container.offsetHeight

      this.clear()
    },

    clear() {
      this.blank = true
      this.ctx.fillStyle = this.background
      this.ctx.fillRect( 0, 0, this.canvas.width, this.canvas.height )
    }
  },

  beforeDestroy () {
    this.canvas = this.$refs.canvas

    this.canvas.removeEventListener( 'mousedown', this.down )
    this.canvas.removeEventListener( 'mouseup', this.up )
    this.canvas.removeEventListener( 'mousemove', this.move )

    this.canvas.removeEventListener( 'touchstart', this.down )
    this.canvas.removeEventListener( 'touchend', this.up )
    this.canvas.removeEventListener( 'touchmove', this.move )

    window.removeEventListener( 'resize', this.resize )
  },

  mounted() {
    this.canvas = this.$refs.canvas
    this.ctx = this.canvas.getContext( '2d' )

    window.addEventListener( 'resize', this.resize )

    this.clear()
    
    this.canvas.addEventListener( 'mousedown', this.down )
    this.canvas.addEventListener( 'mouseup', this.up )
    this.canvas.addEventListener( 'mousemove', this.move )

    this.canvas.addEventListener( 'touchstart', this.down )
    this.canvas.addEventListener( 'touchend', this.up )
    this.canvas.addEventListener( 'touchmove', this.move )

    this.$nextTick(() => {
      this.resize()
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.sketch-new {
  display: grid;
  grid-template-rows: repeat( 2, auto );
  grid-gap: 18px;
  width: 100%;
  min-width: unset;

  .sketch {
    @include flex;
    position: relative;
    min-height: 400px;
    width: 100%;
    max-width: 100%;

    canvas {
      cursor: crosshair;
      touch-action: none;
      @include box-shadow( $shadow );
      border-radius: 2px;
      @include full-stretch;
    }

    img {
      width: 32px;
      height: 32px;
      background-color: rgba( $white, $opacity-low );
      border-radius: 2px;
      position: absolute;
      padding: 4px;
      top: 0;
      right: 0;
      cursor: pointer;
      margin: 8px 8px 0 0;
    }
  }

  input[ type = file ] { display: none; }

  .buttons {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat( 2, 1fr );
  }
}
</style>
