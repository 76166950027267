<template>
  <div>

    <div class="map-container" ref="map">
      <div class="map" id="map" @zoom="zoom"></div>
    </div>

    <Note v-if="note" :note="note" :close="() => note = null"/>

  </div>
</template>

<script>
import Note from '@/components/notes/note/Container.vue'

import L from 'leaflet'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NotesMap',

  data() {
    return {
      map: null,
      loading: true,
      loaded: false,
      canLoadMore: true,
      error: null,
      note: null,

      mapOptions: {
        attributionControl: false
      },

      //Map tiles
      tileUrl: process.env.VUE_APP_MAP_TILES || 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    }
  },

  components: {
    Note
  },

  computed: {
    ...mapGetters( 'Auth', {
      color: 'color',
      icon: 'icon'
    }),

    ...mapGetters( 'Limits', {
      limit: 'notes'
    }),

    ...mapGetters( 'Notes', {
      notes: 'city'
    }),

    ...mapGetters( 'Location', {
      latitude: 'latitude',
      longitude: 'longitude',
      population: 'population'
    }),

    zoom() {
      return this.$Utils.Map.zoom( this.population )
    },

    position() {
      return [ this.latitude, this.longitude ]
    },
  },

  methods: {
    ...mapActions( 'Notes', {
      fetch: 'getCity'
    }),

    async load() {
      if ( !this.canLoadMore || this.loading ) return

      this.error = null
      this.loading = true

      const location = { latitude: this.latitude, longitude: this.longitude }
      const count = await this.fetch({ ...location, offset: this.offset }).catch( e => this.error = e )

      if ( !this.loaded && !this.error ) this.loaded = true
      this.loading = false

      const limit = this.limit.pagination
      this.canLoadMore = count === limit || this.error
    }
  },

  async mounted() {
    this.map = L.map( 'map', this.mapOptions ).setView( this.position, this.zoom )
    L.tileLayer( this.tileUrl ).addTo( this.map )

    //Notes
    this.notes.forEach( note => {
      const { x, y } = note.coordinates
      const marker = L.marker( [ x, y ], this.$Utils.Map.marker.note( note.type ))
      marker.on( 'click', () => this.note = note ).addTo( this.map )
      //TODO look into
      //L.DomEvent.disableClickPropagation( marker )
    })

    //Me
    const icon = this.$Utils.User.icon( this.icon )
    const marker = L.marker( [ this.latitude, this.longitude ], this.$Utils.Map.marker.user( this.color, icon ))
    marker.addTo( this.map )

    this.loading = false
  },

  beforeDestroy() {
    if ( this.map ) {
      this.map.off()
      this.map.remove()
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.map {
  @include full-stretch;

  &-container {
    position: relative;
    overflow: hidden;
    border-radius: 2px;
    @include box-shadow( $shadow );
    background-color: $off-white;
    z-index: 1;
    height: 100%;
  }
}
</style>