<template>
  <div class="notes page">

    <div v-if="ready" class="content">
      <Map/>

      <Buttons/>
    </div>

    <!-- Error and Loading -->
    <div v-else class="retry">
      <div>

        <h2 v-if="showError" class="bk">{{ error }}</h2>
        <button role="button" alt="Reload" v-if="showError" @click="reload" class="submit circle retry">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><img :src="icons.reload" alt="Reload"/></span>
        </button>
        <Loading v-if="loading"/>

      </div>
    </div>

  </div>
</template>

<script>
import Map from '@/components/notes/Map.vue'
import Buttons from '@/components/notes/Buttons.vue'
import Loading from '@/components/Loading.vue'

import { mapActions } from 'vuex'

export default {
  name: 'Notes',

  data() {
    return {
      loading: true,
      error: null,
      icons: {
        reload: require( '@/assets/icons/shared/reload.svg' )
      }
    }
  },

  metaInfo() {
    return {
      title: this.$router.options.title( this.$route )
    }
  },

  components: {
    Map,
    Buttons,
    Loading
  },

  computed: {
    ready() {
      return !this.error && !this.loading
    },

    showError() {
      return this.error && !this.loading
    }
  },

  methods: {
    ...mapActions( 'Notes', {
      fetch: 'getCity',
      count: 'count'
    }),

    async load() {
      this.error = null
      this.loading = true

      await Promise.all([ this.fetch({ offset: 0 }), this.count() ]).catch( e => this.error = e )

      this.loading = false
    },

    async reload() {
      this.error = null
      await this.load()
    }
  },

  async mounted() {
    await this.load()
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.notes {
  padding: $page-padding;

  .content {
    display: grid;
    height: 100%;
    grid-gap: $page-padding;
    grid-template-rows: 1fr auto;
  }
}

</style>
