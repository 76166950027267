<template>
  <div :title="tooltip">

      <div>
        <Compass :bearing="bearing" :distance="distance"/>
      </div>

      <p class="distance">{{ distance }}<span>km</span></p>
      <p class="direction">{{ direction }}</p>
      
  </div>
</template>

<script>
import Compass from '@/components/notes/note/preview/Compass.vue'

import Filters from '@/utils/filters'
import { mapGetters } from 'vuex'

export default {
  name: 'NotesNotePreviewDistance',

  props: {
    note: Object
  },

  components: {
    Compass
  },

  filters: {
    ...Filters
  },

  computed: {
    ...mapGetters( 'Location', {
      latitude: 'latitude',
      longitude: 'longitude'
    }),

    bearing() {
      const { x, y } = this.note.coordinates
      return this.$Utils.Location.bearing( this.latitude, this.longitude, x, y )
    },

    distance() {
      const { x, y } = this.note.coordinates
      const distance = this.$Utils.Location.distance( this.latitude, this.longitude, x, y )
      return Math.round( distance * 100) / 100
    },

    direction() {
      return this.$Utils.Location.direction( this.bearing )
    },

    tooltip() {
      return `${ this.distance } km ${ this.direction }`
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

p.distance {
  text-align: center;
  padding-top: 12px;
  font-size: 20px;

  span {
    display: inline-block;
    font-size: 12px;
    padding-left: 2px;
    font-weight: bolder;
  }
}

p.direction {
  text-align: center;
  font-size: 12px;
  font-weight: bolder;
}
</style>
