<template>
  <div class="text-new">

      <!-- Text -->
      <textarea placeholder="Write a note" v-model="text" :maxlength="max"/>
      <p class="remaining">{{ remaining }} Characters remaining</p>

      <div class="buttons">
        <!-- Cancel -->
        <button role="button" @click.stop="close" alt="Cancel" class="submit reject">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><p>Cancel</p></span>
        </button>

        <!-- Drop -->
        <button role="button" class="submit" alt="Drop" @click.prevent="submit" :class="{ disabled: !canDrop }">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><p>Drop</p><span class="coin" v-if="showCoin"><img :src="icons.coin" alt="Coin"/></span><p v-if="showCoin">{{ cost }}</p></span>
        </button>
      </div>

      <Insufficient v-if="insufficient" :close="() => this.insufficient = false" :price="this.price"/>

  </div>
</template>

<script>
import Insufficient from '@/components/shop/Insufficient.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NotesNewText',

  data() {
    return {
      loading: false,
      text: '',
      insufficient: false,
      icons: {
        coin: require( '@/assets/icons/shared/coin.svg' )
      }
    }
  },

  props: {
    close: Function
  },

  components: {
    Insufficient
  },

  computed: {
    ...mapGetters( 'Limits', {
      limit: 'notes'
    }),

    ...mapGetters( 'Prices', {
      price: 'note'
    }),

    ...mapGetters( 'Auth', {
      balance: 'balance'
    }),

    max() {
      return this.limit.characters
    },

    remaining() {
      return this.max - this.text.length
    },

    canDrop() {
      return this.text.trim() && !this.loading
    },

    showCoin() {
      return this.cost && this.canDrop
    },

    cost() {
      return this.price ? this.$Utils.Filters.abbriviate( this.price ) : null
    }
  },

  methods: {
    ...mapActions( 'Notes', {
      new: 'new'
    }),

    async submit() {
      if ( !this.canDrop ) return
      if ( this.price && this.balance < this.price ) return this.insufficient = true
      this.new({ text: this.text })
      this.close()
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.text-new {
  display: grid;
  grid-template-rows: repeat( 3, auto );
  grid-gap: 18px;
  width: 100%;
  
  textarea {
    border-radius: 2px;
  }

  .remaining {
    font-size: 16px;
    text-align: left;
  }

  .buttons {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat( 2, 1fr );
  }
}
</style>
