<template>
  <div class="note-details">

    <router-link :title="name" :alt="name" class="profile" :style="style" :to="{ name: 'users', params: { id: note.user }}">
      <svg v-if="icon" v-html="icon"/>
    </router-link>

    <!-- Top -->
    <h2>{{ title }}</h2>

    <!-- Bottom -->
    <div class="footer">

      <p class="date" :title="note.createdAt">{{ note.createdAt | date }}</p>

      <span class="views" :title="tooltip">
        <img :src="icons.eye" alt="Eye"/>
        <p>{{ views }}</p>
      </span>

    </div>
      
  </div>
</template>

<script>
import Filters from '@/utils/filters'
import { mapGetters } from 'vuex'

export default {
  name: 'NotesNotePreviewDetails',

  data() {
    return {
      icons: {
        eye: require( '@/assets/icons/notes/eye.svg' )
      }
    }
  },

  props: {
    note: Object
  },

  filters: {
    ...Filters
  },

  computed: {
    ...mapGetters( 'Auth', {
      me: 'id'
    }),

    style() {
      return this.$Utils.User.image( this.note.profile, this.note.color )
    },

    name() {
      return this.$Utils.User.name( this.note )
    },
    
    icon() {
      if ( this.note.profile ) return
      return this.$Utils.User.icon( this.note.icon )
    },

    title() {
      const note = this.isText ? 'a note' : ( this.isImage ? 'an image' : 'a sketch' )
      return `${ this.name } has left ${ note }`
    },

    isText() {
      return this.note.type.toUpperCase() === 'TEXT'
    },

    isImage() {
      return this.note.type.toUpperCase() === 'IMAGE'
    },

    isSketch() {
      return this.note.type.toUpperCase() === 'SKETCH'
    },

    views() {
      const views = this.note.views
      return views >= 100000 ? this.$Utils.Filters.abbriviate( views ) : views.toLocaleString()
    },

    distance() {
      const { latitude, longitude } = this.location
      const { x, y } = this.note.coordinates
      return this.$Utils.Location.distance( latitude, longitude, x, y )
    },

    tooltip() {
      return `${ this.views } Views`
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.note-details {
  display: grid;
  grid-template-areas: "profile title" "profile footer";
  grid-template-columns: auto 1fr;
  grid-gap: 0 16px;

  //Note icon
  img {
    width: 64px;
    height: 64px;
    margin: 0 auto;
  }

  //Profile picture
  .profile {
    grid-area: profile;
    $size: 58px;
    width: $size;
    height: $size;
    min-width: $size;
    margin: 0;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  //Title
  h2 {
    grid-area: title;
    font-size: 16px;
    font-family: $font;
    text-align: left;
    @include flex-config( false, flex-start, flex-end );
  }

  .footer {
    grid-area: footer;
    @include flex-config( false, space-between, center );

    .date {
      font-size: 12px;
    }
  }

  //Eye
  .views {
    @include flex-center;

    img {
      width: 16px;
      height: 16px;
    }

    p {
      padding-left: 8px;
    }
  }
}
</style>
