<template>
  <div class="note-types">

    <span class="text" @click.stop="select( 'TEXT' )">
      <img :src="icons.text" title="Text" alt="Text"/>
      <p>Text</p>
    </span>

    <span class="image" @click.stop="select( 'IMAGE' )">
      <img :src="icons.image" title="Image" alt="Image"/>
      <p>Image</p>
    </span>

    <span class="sketch" @click.stop="select( 'SKETCH' )">
      <img :src="icons.sketch" title="Sketch" alt="Sketch"/>
      <p>Sketch</p>
    </span>

  </div>
</template>

<script>
export default {
  name: 'NotesNewTypes',

  data() {
    return {
      icons: {
        text: require( '@/assets/icons/notes/text.svg' ),
        image: require( '@/assets/icons/notes/image.svg' ),
        sketch: require( '@/assets/icons/notes/sketch.svg' )
      }
    }
  },

  props: {
    select: Function
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.note-types {
  display: grid;
  grid-gap: 16px;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;

  span {
    @include box-shadow( $shadow );
    padding: 16px;
    cursor: pointer;
    text-align: center;
    background-color: $off-white;

    &:hover {
      background-color: $white;
    }

    img {
      width: 24px;
      height: 24px;
    }

    p {
      text-align: center;
      padding-top: 12px;
    }
  }
}
</style>
