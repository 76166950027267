<template>
  <div class="modal-container bk" @mousedown.stop="close">
    <div class="note modal" @mousedown.stop>

      <Reveal v-if="revealed" :noteId="note.id"/>

      <Preview v-else :note="note" :submit="() => this.revealed = true" :close="close"/>

    </div>
  </div>
</template>

<script>
import Preview from '@/components/notes/note/preview/Container.vue'
import Reveal from '@/components/notes/note/Reveal.vue'

export default {
  name: 'NotesNote',

  data() {
    return {
      revealed: false
    }
  },

  props: {
    note: Object,
    close: Function
  },

  components: {
    Preview,
    Reveal
  }
}
</script>