<template>
  <div class="notes-buttons">

    <!-- Drop -->
    <button role="button" class="submit" alt="Drop note" @click.stop="drop = true">
      <span class="shadow"></span>
      <span class="edge"></span>
      <span class="content"><p>Drop note</p></span>
    </button>

    <!-- My notes -->
    <button v-if="hasNotes" role="button" class="submit" alt="Your notes" @click.stop="list = true">
      <span class="shadow"></span>
      <span class="edge"></span>
      <span class="content"><p>Your notes</p></span>
    </button>

    <New v-if="drop" :close="() => this.drop = false"/>
    <List v-if="list" :close="() => this.list = false"/>

  </div>
</template>

<script>
import New from '@/components/notes/new/Container.vue'
import List from '@/components/notes/mine/Container.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'NotesButtons',

  data() {
    return {
      list: false,
      drop: false
    }
  },

  components: {
    New,
    List
  },

  computed: {
    ...mapGetters( 'Notes', {
      total: 'total',
      count: 'countMine'
    }),

    hasNotes() {
      return this.total > 0 || this.count > 0
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.notes-buttons {
  display: block;

  button {
    &:not( :first-child ) {
      margin-left: 16px;
    }
  }
}
</style>
